<template>
  <div class="kingCallshareAct" :style="tackBack()">
    <div class="logo">
      <img
        :src="picName !==''
        ?'https://ad-static-xg.tagtic.cn/ad-material/SDKL666-'+picName+'-2-'+(method?method=='send'?'2':'3':'1' )+'.png'
        :require('../../static/img/logo.png')" alt="">
    </div>
    <div class="imgs">
    </div>
    <div class="btn" :class="{'bot340': method=='send'}" @click="gotoApk">
      <img :src="method?method=='send'? require('../../static/img/share-btn-bill2.png'):require('../../static/img/share-btn-bill3.png') :require('../../static/img/share-btn-bill1.png')" alt="">
    </div>
    <Wxpopup v-if="isWeixin" />
    <img class="load" src="../../static/img/loading.gif" v-if="loading" alt="">
  </div>
</template>
<script>
import Wxpopup from '../components/Wxpopup'
// import ajax from '../service/index'
export default {
  data () {
    return {
      apk: '',
      isWeixin: false,
      isApprentice: '2',
      user_id: '',
      invite_code: '',
      paramsHref: '',
      loading: false,
      picName: '',
      package: '',
      method: '',
      shareCardId: '',
      isHave: true
    }
  },
  components: {
    Wxpopup
  },
  created () {
    const url = location.href
    if (url.indexOf('?') >= 0) {
      var params = url.substring((url.indexOf('?') + 1), url.length)
      this.paramsHref = params
      if (params !== null && params !== undefined && params !== '') {
        const paramArr = params.split('&')
        for (const index in paramArr) {
          const key = paramArr[index].split('=')[0]
          const val = paramArr[index].split('=')[1]
          console.log(val)
          switch (key) {
            case 'package_name':
              this.package = val
              break
            case 'method':
              this.method = val
              break
            case 'shareCardId':
              this.shareCardId = val
              break
            default:
              break
          }
        }
      }
    }
    if (this.package.includes('skin')) {
      const items = this.package.substring((this.package.lastIndexOf('.') + 1), url.length)
      this.picName = items
    }
    // this.gotoApk(channel, inviteCode, packageName, userId, versionCode, scheme)
  },
  mounted () {
  },
  methods: {
    gotoApk () {
      if (!this.scheme || this.scheme === '') {
        if (this.shareCardId) {
          this.scheme = 'sharecard://' + this.package + '?shareCardId=' + this.shareCardId
        } else {
          this.scheme = 'charge://' + this.package
        }
      }
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf('micromessenger') !== -1
      if (isWeixin) {
        this.isWeixin = true
      } else {
        this.isWeixin = false
        // window.location.href = this.apk
        if (this.getDeviceType() === 'android') {
          const iframes = document.createElement('iframe')
          iframes.src = this.scheme
          // iframes.setAttribute('style', 'display:none;')
          iframes.setAttribute('height', '0rem')
          iframes.setAttribute('width', '0rem')
          iframes.setAttribute('frameborder', '0')
          document.body.appendChild(iframes)
          this.loading = true

          console.log('paramsHref', this.paramsHref)
          this.timers = setTimeout(() => {
            this.loading = false
            // 正式地址
            // const htp = 'https://mtasks.dev.tagtic.cn/'
            const htp = 'https://ad-static-xg.tagtic.cn/'
            window.location.href = htp + 'mtasks/api/apk/pack/' + this.paramsHref
          }, 5000)
        }
        if (this.getDeviceType() === 'ios') {
          this.loading = true
          const scheme = this.package.split('.')
          console.log(scheme[scheme.length - 1])
          window.location.href = scheme[scheme.length - 1] + '://' + this.package
          this.timers = setTimeout(() => {
            this.loading = false
            // window.location.href = 'https://apps.apple.com/cn/app/id'
          }, 5000)
        }

        this.detectionOut()
      }
    },
    detectionOut () {
      document.addEventListener('visibilitychange', () => {
        var isHidden = document.hidden
        if (isHidden) {
          // 离开页面
          console.log('==离开页面==')
        } else {
          // 进入页面
          clearTimeout(this.timers)
          this.loading = false
        }
      })
    },
    tackBack () {
      if (this.picName !== '') {
        let type = '1'
        if (!this.method || this.method === '') {
          type = '1'
        } else if (this.method === 'send') {
          type = '2'
        } else if (this.method === 'claim') {
          type = '3'
        }
        return { backgroundImage: 'url(https://ad-static-xg.tagtic.cn/ad-material/SDKL666-' + this.picName + '-1-' + type + '.png)' }
      } else {
        // return { backgroundImage: 'url(../../static/recharge-hbsm-1.png)' }
      }
    },
    getDeviceType () {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        return 'android'
      }
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      console.log('isiOS', isiOS)
      if (isiOS) {
        return 'ios'
      }
      return 'android'
    }
  }
}
</script>
<style lang="scss" scoped="scoped">

.kingCallshareAct {
  width: 100%;
  height: 14.9rem;
  background: url('../../static/img/shareActbg.png') no-repeat center;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: .92rem;
  text-align: center;
  position: relative;
  .logo {
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #FFFFFF;
    img{
      height: 2.42rem;
      margin: 0 auto 0;
    }
  }
  .btn {
    width: 3.36rem;
    height: 1.02rem;
    margin:0 auto;
    position: absolute;
    bottom: 1.58rem;
    left: 50%;
    transform: translateX(-50%);
    &.bot340{
      bottom: 3.4rem;
    }
    img {
      width: 100%;
    }

  }
  .load {
    width: 2.8rem;
    height: 2.8rem;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
