<template>
  <div class="xyxq">
      <input id="input" value="这是幕后黑手" style="opacity: 0; position: absolute">
    <div class="btn" @click="gotoApk"></div>
    <Wxpopup v-if="isWeixin" />
    <img
      class="load"
      src="../../static/img/loading.gif"
      v-if="loading"
      alt=""
    />
  </div>
</template>

<script>
import Wxpopup from '../components/Wxpopup'

export default {
  data () {
    return {
      logo: '',
      //   name: "吕智江",
      //   number: "376044250",
      show: false,
      isWeixin: false,
      apk: '',
      isWeixin: false,
      isApprentice: '2',
      user_id: '',
      invite_code: '',
      paramsHref: '',
      loading: false,
      picName: '',
      package: '',
      isHave: true,
      skuId: '',
      path: '',
      channel: '',
      version_code: '',
      package_name: ''
    }
  },
  components: {
    Wxpopup
  },
  created () {
    const url = location.href
    // const url = 'https://recharge-share.dev.tagtic.cn/#/xyxq?channel=doNews_channel_share&invite_code=DJxwkr43qW&package_name=com.star.wishing&user_id=41418646&version_code=10000&picName=recharge-wishing&skuId=0&path=%2Ftask%2FTaskInvitePage'
    console.log('Appurl', url)
    if (url.indexOf('?') >= 0) {
      var params = url.substring(url.indexOf('?') + 1, url.length)
      this.paramsHref = params
      if (params !== null && params !== undefined && params !== '') {
        const paramArr = params.split('&')
        for (const index in paramArr) {
          const key = paramArr[index].split('=')[0]
          const val = paramArr[index].split('=')[1]
          console.log(val)
          switch (key) {
            case 'isApprentice':
              this.isApprentice = val
              break
            case 'scheme':
              this.scheme = decodeURIComponent(val)
              break
            case 'picName':
              this.picName = val
              break
            case 'package_name':
              this.package_name = val
              break
            case 'skuId':
              this.skuId = val
              break
            case 'path':
              this.path = val
              break
            case 'invite_code':
              this.invite_code = val
              break
            case 'channel':
              this.channel = val
              break
            case 'user_id':
              this.user_id = val
              break
            case 'version_code':
              this.version_code = val
              break
            default:
              break
          }
        }
      }
    }
    console.log('skuId', this.skuId)
    console.log('scheme', this.scheme, this.picName)
    if (this.package.includes('skin') && !this.picName) {
      const items = this.package.substring(
        this.package.lastIndexOf('.') + 1,
        url.length
      )
      this.picName = items
    } else if (this.package.includes('sesame')) {
      const items = this.package.substring(
        this.package.lastIndexOf('.') + 1,
        url.length
      )
      this.picName = items
    }
    // this.gotoApk(channel, inviteCode, packageName, userId, versionCode, scheme)
  },
  methods: {
    //   打开app
    gotoApk () {
      let paramsHref = ''
      if (!this.scheme || this.scheme === '') {
        paramsHref = this.paramsHref
        this.scheme = 'charge://' + this.package
      } else {
        if (this.paramsHref.indexOf('scheme') !== -1) {
          paramsHref = this.paramsHref.substring(
            0,
            this.paramsHref.indexOf('scheme') - 1
          )
        } else {
          paramsHref = this.paramsHref
        }
      }
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf('micromessenger') !== -1
      if (isWeixin) {
        this.isWeixin = true
      } else {
        this.isWeixin = false
        // const url = 'https://recharge-share.dev.tagtic.cn/#/xyxq?channel=doNews_channel_share&invite_code=DJxwkr43qW&package_name=com.star.wishing&user_id=41418646&version_code=10000&picName=recharge-wishing&skuId=0&path=%2Ftask%2FTaskInvitePage'
        const url = location.href
        const isDev = url.indexOf('dev') === -1
        console.log(isDev)
        console.log(url.indexOf('dev'))
        if (this.getDeviceType() === 'android') {
          const iframes = document.createElement('iframe')
          console.log(this.scheme, 'this.scheme')
          iframes.src = 'charge://com.star.wishing' + '?' + 'skuId=' + this.skuId + '&path=' + this.path
          console.log(iframes.src)
          // iframes.setAttribute('style', 'display:none;')
          iframes.setAttribute('height', '0rem')
          iframes.setAttribute('width', '0rem')
          iframes.setAttribute('frameborder', '0')
          document.body.appendChild(iframes)
          console.log(iframes)
          this.loading = true
          console.log('paramsHref', paramsHref)
          this.timers = setTimeout(() => {
            this.loading = false
            const htp = 'https://ad-static-xg.tagtic.cn/'
            window.location.href = htp + 'mtasks/api/apk/pack/' + 'channel=' + this.channel + '&invite_code=' + this.invite_code + '&package_name=' +
            this.package_name + '&user_id=' + this.user_id + '&version_code=' + this.version_code
          }, 5000)
        }
        if (this.getDeviceType() === 'ios') {
          // 剪切板内容
          navigator.clipboard.writeText('charge://' + this.package + '?picName=' + this.picName + '&skuId=' + this.skuId + '&path=' + this.path + '&invite_code=' + this.invite_code)
          this.loading = true
          const scheme = this.package.split('.')
          console.log(scheme[scheme.length - 1])
          window.location.href =
            'charge://' + this.package + '?picName=' + this.picName + '&skuId=' + this.skuId + '&path=' + this.path + '&invite_code=' + this.invite_code
          console.log('charge://' + this.package + '?picName=' + this.picName + '&skuId=' + this.skuId + '&path=' + this.path + '&invite_code=' + this.invite_code)
          this.timers = setTimeout(() => {
            this.loading = false
            // appstore
            const hrf = 'itms-apps://apps.apple.com/cn/app/id1619231086'
            window.location.href = hrf
          }, 5000)
        }
        this.detectionOut()
      }
    },
    // 判断机型
    getDeviceType () {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        return 'android'
      }
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      //   console.log('isiOS', isiOS)
      if (isiOS) {
        return 'ios'
      }
      return 'android'
    },
    // 弹窗
    showpop () {
      this.show = true
    },
    closeshow () {
      this.show = false
    },
    detectionOut () {
      document.addEventListener('visibilitychange', () => {
        var isHidden = document.hidden
        if (isHidden) {
          // 离开页面
          console.log('==离开页面==')
        } else {
          // 进入页面
          clearTimeout(this.timers)
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.xyxq {
  width: 100%;
  height: 100vh;
  background: url(../../static/img/xyxq-1.png) center no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  padding-top: 80vh;
  box-sizing: border-box;
  .btn {
    width: 6rem;
    height: 2.2rem;
    background: url(../../static/img/xyxq-2.png) center no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
  }
  .load {
    width: 2.8rem;
    height: 2.8rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
