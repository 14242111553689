<template>
  <div class="farmyard">
    <!-- <div class="useinfo">
      <img src="../../static/img/farmyard-3.png" alt="" />
      <div class="name">
        <span>{{ name }}</span>
        <p>
          我的邀请码： <span>{{ number }}</span>
        </p>
      </div>
      <div class="btn" @click="copy">复制</div>
    </div> -->
    <div class="pop" @click="showpop">为什么能赚钱？</div>
    <div class="open" @click="gotoApk">立即打开</div>
    <!-- 弹窗 -->
    <div v-if="show" class="popshow">
      <div class="content">
        <div class="title">为什么能赚钱？</div>
        <p>
          答：因为用户在玩游戏的时候产生了广告收益，我们会把约30%甚至更多的收益分给用户，玩的越久赚的越多。
        </p>
        <div class="btnpop" @click="closeshow">我知道了</div>
      </div>
      <div class="closepop" @click="closeshow"></div>
    </div>

    <Wxpopup v-if="isWeixin" />
    <img
      class="load"
      src="../../static/img/loading.gif"
      v-if="loading"
      alt=""
    />
  </div>
</template>

<script>
import Wxpopup from '../components/Wxpopup'

export default {
  data () {
    return {
      logo: '',
      //   name: "吕智江",
      //   number: "376044250",
      show: false,
      isWeixin: false,
      apk: '',
      isWeixin: false,
      isApprentice: '2',
      user_id: '',
      invite_code: '',
      paramsHref: '',
      loading: false,
      picName: '',
      package: '',
      isHave: true
    }
  },
  components: {
    Wxpopup
  },
  created () {
    const url = location.href
    // const url = 'https://mtasks.dev.tagtic.cn/mtasks/api/apk/pack/channel=yynjy&package_name=com.wz.yynjy&version_code=11090'
    console.log('Appurl', url)
    if (url.indexOf('?') >= 0) {
      var params = url.substring(url.indexOf('?') + 1, url.length)
      this.paramsHref = params
      if (params !== null && params !== undefined && params !== '') {
        const paramArr = params.split('&')
        for (const index in paramArr) {
          const key = paramArr[index].split('=')[0]
          const val = paramArr[index].split('=')[1]
          console.log(val)
          switch (key) {
            case 'isApprentice':
              this.isApprentice = val
              break
            case 'scheme':
              this.scheme = decodeURIComponent(val)
              break
            case 'picName':
              this.picName = val
              break
            case 'package_name':
              this.package = val
              break
            default:
              break
          }
        }
      }
    }
    console.log('scheme', this.scheme, this.picName)
    if (this.package.includes('skin') && !this.picName) {
      const items = this.package.substring(
        this.package.lastIndexOf('.') + 1,
        url.length
      )
      this.picName = items
    } else if (this.package.includes('sesame')) {
      const items = this.package.substring(
        this.package.lastIndexOf('.') + 1,
        url.length
      )
      this.picName = items
    }
    // this.gotoApk(channel, inviteCode, packageName, userId, versionCode, scheme)
  },
  methods: {
    //   打开app
    gotoApk () {
      let paramsHref = ''
      if (!this.scheme || this.scheme === '') {
        paramsHref = this.paramsHref
        this.scheme = 'charge://' + this.package
      } else {
        if (this.paramsHref.indexOf('scheme') !== -1) {
          paramsHref = this.paramsHref.substring(
            0,
            this.paramsHref.indexOf('scheme') - 1
          )
        } else {
          paramsHref = this.paramsHref
        }
      }
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf('micromessenger') !== -1
      if (isWeixin) {
        this.isWeixin = true
      } else {
        this.isWeixin = false
        // window.location.href = this.apk
        // const url ='https://mtasks.dev.tagtic.cn/mtasks/api/apk/pack/channel=yynjy&package_name=com.wz.yynjy&version_code=11090'
        const url = location.href
        const isDev = url.indexOf('dev') === -1
        console.log(isDev)
        console.log(url.indexOf('dev'))
        if (this.getDeviceType() === 'android') {
          const iframes = document.createElement('iframe')
          iframes.src = this.scheme
          // iframes.setAttribute('style', 'display:none;')
          iframes.setAttribute('height', '0rem')
          iframes.setAttribute('width', '0rem')
          iframes.setAttribute('frameborder', '0')
          document.body.appendChild(iframes)
          console.log(iframes)
          this.loading = true
          console.log('paramsHref', paramsHref)
          this.timers = setTimeout(() => {
            this.loading = false
            // 正式地址
            const htp = 'https://ad-static-xg.tagtic.cn/'
            window.location.href = htp + 'mtasks/api/apk/pack/' + paramsHref
          }, 5000)
        }
        if (this.getDeviceType() === 'ios') {
          this.loading = true
          const scheme = this.package.split('.')
          console.log(scheme[scheme.length - 1])
          window.location.href =
            scheme[scheme.length - 1] + '://' + this.package
          this.timers = setTimeout(() => {
            this.loading = false
            const hrf =
              this.package === 'com.sesame.happyplanet'
                ? 'https://apps.apple.com/cn/app/id1578449597'
                : ''
            window.location.href = hrf
          }, 5000)
        }

        this.detectionOut()
      }
    },
    // 判断机型
    getDeviceType () {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        return 'android'
      }
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      //   console.log('isiOS', isiOS)
      if (isiOS) {
        return 'ios'
      }
      return 'android'
    },
    // 弹窗
    showpop () {
      this.show = true
    },
    closeshow () {
      this.show = false
    },
    detectionOut () {
      document.addEventListener('visibilitychange', () => {
        var isHidden = document.hidden
        if (isHidden) {
          // 离开页面
          console.log('==离开页面==')
        } else {
          // 进入页面
          clearTimeout(this.timers)
          this.loading = false
        }
      })
    },
    copy () {
      console.log(this.number)
      this.$copyText(this.number)
        .then((e) => {
          alert('复制成功')
        })
        .catch((err) => {
          alert('复制失败')
        })
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.farmyard {
  width: 100%;
  height: 14.48rem;
  background: url(../../static/img/farmyard-1.png) center no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  padding-top: 1.9rem;
  box-sizing: border-box;
  .useinfo {
    width: 6.92rem;
    height: 1.9rem;
    background: url(../../static/img/farmyard-2.png) center no-repeat;
    background-size: 100% 100%;
    margin: 0.4rem auto 0;
    padding: 0.44rem 0 0.46rem 0.32rem;
    box-sizing: border-box;
    display: flex;
    img {
      width: 1rem;
      height: 1rem;
      margin-right: 0.18rem;
    }
    .name {
      text-align: start;
      span {
        font-size: 0.34rem;
        color: #6a2d20;
      }
      p {
        font-size: 0.32rem;
        color: #8d4a3c;
        margin-top: 0.1rem;
        span {
          font-size: 0.35rem;
          color: #d11111;
          font-weight: 700;
        }
      }
    }
    .btn {
      width: 1.08rem;
      height: 0.52rem;
      background: url(../../static/img/farmyard-4.png) center no-repeat;
      background-size: 100% 100%;
      margin: 0.5rem 0 0 0.2rem;
      line-height: 0.52rem;
      color: #fff4ff;
      font-weight: 700;
      font-size: 0.24rem;
    }
  }
  .pop {
    width: 3.32rem;
    height: 0.86rem;
    background: url(../../static/img/farmyard-5.png) center no-repeat;
    background-size: 100% 100%;
    margin: 5rem 0 0 4.18rem;
    line-height: 0.86rem;
    color: #f45a2f;
    font-size: 0.36rem;
    font-weight: 700;
    padding-left: 0.62rem;
    box-sizing: border-box;
  }
  .open {
    width: 4.24rem;
    height: 1.34rem;
    background: url(../../static/img/farmyard-6.png) center no-repeat;
    background-size: 100% 100%;
    font-family: DFYuanW9-GB, DFYuanW9;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.2rem;
    font-size: 0.56rem;
    font-weight: 700;
    margin: 3.4rem auto 0;
  }
  .popshow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 14.48rem;
    background: rgba(0, 0, 0, 0.65);
    .content {
      width: 4.84rem;
      height: 6.22rem;
      background: url(../../static/img/farmyard-7.png) center no-repeat;
      background-size: 100% 100%;
      margin: 3.68rem auto 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-content: flex-start;
      .title {
        font-size: 0.44rem;
        font-weight: 700;
        color: #fe7846;
        margin-top: 1.1rem;
      }
      p {
        color: #e7865a;
        font-size: 0.3rem;
        width: 3.9rem;
        height: 1.84rem;
        margin-top: 0.3rem;
      }
      .btnpop {
        width: 3.4rem;
        height: 0.8rem;
        background: url(../../static/img/farmyard-9.png) center no-repeat;
        background-size: 100% 100%;
        margin-top: 0.36rem;
        color: #fff;
        font-size: 0.34rem;
        line-height: 0.8rem;
      }
    }
    .closepop {
      width: 0.52rem;
      height: 0.52rem;
      background: url(../../static/img/farmyard-8.png) center no-repeat;
      background-size: 100% 100%;
      margin: 0.44rem auto 0;
    }
  }
}
</style>
